import React from 'react';
import SEO from '../components/seo';
import TitlePage from '../components/TitlePage';
import ContactForm from '../components/ContactForm';
import useTranslations from '../components/useTranslations';

const ContactUs = () => {
  const {
    contact
  } = useTranslations();

  return (<>
      <SEO title="Contact Us Page" />
      <TitlePage text={contact} />
      <ContactForm />
    </>
  );
}
export default ContactUs;
