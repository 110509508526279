import styled from 'styled-components';
import media from 'styled-media-query';

export const ContactFormWrapper = styled.div`
  margin-top: 3rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

export const ContactFormContainer = styled.div`
  table {
    width: -webkit-fill-available;
  };
  tr {
    width: -webkit-fill-available;
  };
  td {
      padding: 2px;
      width: -webkit-fill-available;
  };
  input {
    width: -webkit-fill-available;
  };
  textarea {
    width: -webkit-fill-available;
  };
`;
