import React from 'react';
import * as S from './styled';

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { name: "", email: "", message: "" };
  }

  encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
  };

  handleSubmit = e => {
    e.preventDefault();
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: this.encode({ "form-name": "contact", ...this.state })
    }).then(() => alert("Success!")).catch(error => alert(error));
  };

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    const { name, email, message } = this.state;
    return (
      <S.ContactFormWrapper>
      <S.ContactFormContainer>
        <form name="contact" data-netlify="true" onSubmit={this.handleSubmit}>
          <table>
            <tr>
              <td>Name:</td>
              <td>
                <input type="text" name="name" value={name} onChange={this.handleChange} />
              </td>
            </tr>
            <tr>
              <td>
                <label>
                  Email:
                </label>
              </td>
              <td>
                <input type="email" name="email" value={email} onChange={this.handleChange} />
              </td>
            </tr>
            <tr>
              <td>Message:</td>
              <td>
                <textarea name="message" value={message} onChange={this.handleChange} />
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <button type="submit">Send</button>
              </td>
            </tr>
          </table>
        </form>
      </S.ContactFormContainer>
      </S.ContactFormWrapper>
    );
  }
}

export default ContactForm;